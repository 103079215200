import React from "react"
import Layout from "../../../layouts/WIELayout"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"

export default function WIE({ data }) {
  return (
    <Layout>
      <div className="container mx-auto py-5">
        <div className="row bg-white shadow rounded-xl py-3">
          <div className="col-lg-8 align-self-center">
            <div className="h-100">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    height={200}
                    src="../../../images/WIE_logo.webp"
                    alt="Logo of WIE"
                  />
                </div>
                <div className="cardTitle">About Wie</div>
                <div className="card-text px-3">
                  IEEE Women in Engineering (WIE) is a global network of IEEE
                  members and volunteers dedicated to promoting women engineers
                  and scientists, and inspiring girls around the world to follow
                  their academic interests in a career in engineering and
                  science.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4  align-self-center">
            <div className="card-body">
              <div className="cardTitle">Mission & Vision</div>
              <div className="card-text">
                Our goal is to facilitate the recruitment and retention of women
                in technical disciplines globally. We envision a vibrant
                community of IEEE women and men collectively using their diverse
                talents to innovate for the benefit of humanity.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto my-4">
        <div className="heading">
          Execom
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2023-24/WiE - Chair - Thameena Parveen.JPG"
              alt="Thameena"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Thameena Parveen</p>
            <p>Chairperson</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2023-24/WiE - Vice Chair - Gemima VA.jpg"
              alt="Gemima"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Gemima V A</p>
            <p>Vice Chairperson</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2023-24/WiE - Secretary - SANDRA RAMESH.jpg"
              alt="Sandra Ramesh"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Sandra Ramesh</p>
            <p>Secretary</p>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          Events
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center justify-content-center my-4">
          {data.allMarkdownRemark.edges.map(el => (
            <Link
              key={el.node.frontmatter.title}
              className="col card my-2 shadow"
              to={`../../reg/${el.node.frontmatter.slug}`}
              style={{ maxWidth: "18rem" }}
            >
              <div>
                <GatsbyImage
                  image={getImage(el.node.frontmatter.image)}
                  alt={el.node.frontmatter.title}
                  className="card-img"
                />
              </div>
              <div className="card-body d-flex felx-column center">
                <div className="fw-bold text-center text-dark">
                  {el.node.frontmatter.title}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="d-flex justify-content-center my-4">
          <Link className="btn btn-primary" to="/reg">
            View More Events
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query WIE {
    allMarkdownRemark(
      filter: { frontmatter: { by: { regex: "/WIE/i" } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            by
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
